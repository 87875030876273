@keyframes bounceEffect {
    0% {
        transform: rotate(0deg) scale(1);
    }

    25% {
        transform: rotate(-15deg) scale(1.1);
    }

    50% {
        transform: rotate(15deg) scale(1.1);
    }

    75%{
        transform: rotate(-15deg) scale(1.1);
    }

    100%{
        transform: rotate(0deg) scale(1);
    }
}