.skills {
    padding: 0 10%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 200px;
        &__content {
            width: 45%;
                &__title {
                    font-family: 'AmericaExpRG';
                    font-size: 44px;
                        @media (max-width: 600px) {
                            font-size: 30px;
                        }
                }
                &__subtitle {
                    color: #4e4e4e;
                    font-size: 24px;
                    line-height: 30px;
                    margin-bottom: 60px;
                        @media (max-width: 600px) {
                            font-size: 18px;
                            line-height: 24px;
                            margin-bottom: 40px;
                        }
                }
                &__list {
                    position: relative;
                        &__line {
                            content: '';
                            background-color: #7B7B7B;
                            position: absolute;
                            height: 1px;
                            width: 88%;
                        }
                        &__text {
                            font-size: 24px;
                            font-weight: 300;
                            padding-top: 20px;
                            margin-bottom: 20px;
                                @media (max-width: 600px) {
                                    font-size: 20px;
                                    padding-top: 15px;
                                    margin-bottom: 15px;
                                }
                        }
                }
                @media (max-width: 1450px) {
                    width: auto;
                }
        }
        &__illustration {
            width: 45%;
            position: relative;
                &__part {
                    position: absolute;
                    width: 100%;
                    border-radius: 6px;
                        &--coding {
                            top: 120px;
                        }
                        &--phone {
                            box-shadow: 0px 4px 15px -8px rgba(0, 0, 0, 0.5);
                            width: 28%;
                            top: 250px;
                            left: -10%;
                                @media (max-width: 1450px) {
                                    width: 23%;
                                    top: 300px;
                                    left: -7%;
                                }
                                @media (max-width: 850px) {
                                    min-width: 130px;
                                    top: 180px;
                                }
                                @media (max-width: 580px) {
                                    min-width: 80px;
                                    top: 210px;
                                }
                                @media (max-width: 530px) {
                                    top: 190px;
                                }
                                @media (max-width: 480px) {
                                    top: 160px;
                                }
                        }
                        &--terminal {
                            box-shadow: 0px 4px 15px -2px rgba(0, 0, 0, 0.3);
                            width: 55%;
                            right: -10%;
                            top: 80px;
                                @media (max-width: 1450px) {
                                    width: 48%;
                                    top: 100px;
                                    right: -7%;
                                }
                                @media (max-width: 700px) {
                                    min-width: 260px;
                                }
                        }
                        &--trackizer {
                            top: 190px;
                                @media (max-width: 550px) {
                                    top: 110px;
                                }
                        }
                        &--colorSelect {
                            box-shadow: 0px 4px 15px 8px rgba(0, 0, 0, 0.5);
                            width: 28%;
                            top: 30px;
                            left: -10%;
                                @media (max-width: 1450px) {
                                    width: 23%;
                                    left: -8%;
                                }
                                @media (max-width: 800px) {
                                    top: 60px;
                                }
                                @media (max-width: 550px) {
                                    min-width: 70px;
                                    top: 0px;
                                }
                        }
                        &--layers {
                            box-shadow: 0px 4px 15px 8px rgba(0, 0, 0, 0.5);
                            width: 33%;
                            top: 500px;
                            left: 27%;
                                @media (max-width: 1450px) {
                                    width: 25%;
                                    top: 780px;
                                }
                                @media (max-width: 1100px) {
                                    top: 670px;
                                }
                                @media (max-width: 800px) {
                                    top: 550px;
                                }
                                @media (max-width: 650px) {
                                    top: 500px;
                                }
                                @media (max-width: 550px) {
                                    min-width: 80px;
                                    top: 400px;
                                }
                                @media (max-width: 450px) {
                                    top: 350px;
                                }
                                @media (max-width: 380px) {
                                    top: 310px;
                                }
                        }
                        &--colorStyle {
                            box-shadow: 0px 4px 15px 8px rgba(0, 0, 0, 0.5);
                            width: 25%;
                            top: 250px;
                            right: -10%;
                                @media (max-width: 1450px) {
                                    width: 20%;
                                    top: 250px;
                                    right: -6%;
                                }
                                @media (max-width: 550px) {
                                    min-width: 60px;
                                    top: 100px;
                                }
                                @media (max-width: 380px) {
                                    top: 70px;
                                }
                        }
                }
                @media (max-width: 1450px) {
                    width: auto;
                }
                @media (max-width: 700px) {
                    margin-top: -30px;
                }
        }
        &--design {
            @media (max-width: 1450px) {
                flex-direction: column-reverse!important;
                margin-top: 1100px;
                margin-bottom: 1200px;
            }
            @media (max-width: 1400px) {
                margin-top: 1000px;
            }
            @media (max-width: 1200px) {
                margin-top: 900px;
            }
            @media (max-width: 1100px) {
                margin-bottom: 1050px;
            }
            @media (max-width: 1000px) {
                margin-top: 800px;
            }
            @media (max-width: 850px) {
                margin-top: 680px;
                margin-bottom: 900px;
            }
            @media (max-width: 700px) {
                margin-top: 580px;
                margin-bottom: 800px;
            }
            @media (max-width: 580px) {
                margin-top: 530px;
                margin-bottom: 700px;
            }
            @media (max-width: 550px) {
                margin-bottom: 600px;
            }
            @media (max-width: 480px) {
                margin-top: 470px;
                margin-bottom: 550px;
            }
            @media (max-width: 430px) {
                margin-bottom: 450px;
            }
        }
        @media (max-width: 1450px) {
            flex-direction: column;
            justify-content: center;
        }
}