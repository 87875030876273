.home {
    padding: 0 10%;
    position: relative;
    margin: 250px 0;
        &__presentation {
                &__title {
                    text-align: center;
                    font-family: 'AmericaExpTh';
                    font-size: 74px;
                    line-height: 84px;
                    width: 75%;
                    margin: auto;
                    margin-bottom: 20px;
                        @media (max-width: 1100px) {
                            width: 100%;
                        }
                        @media (max-width: 768px) {
                            font-size: 60px;
                            line-height: 70px;
                        }
                        @media (max-width: 600px) {
                            font-size: 45px;
                            line-height: 55px;
                        }
                        @media (max-width: 450px) {
                            font-size: 32px;
                            line-height: 42px;
                        }
                }
                &__subtitle {
                    text-align: center;
                    color: #9E9E9E;
                    font-size: 26px;
                    line-height: 34px;
                    width: 60%;
                    margin: auto;
                        @media (max-width: 1100px) {
                            width: 85%;
                        }
                        @media (max-width: 768px) {
                            font-size: 22px;
                            line-height: 30px;
                            width: 95%;
                        }
                        @media (max-width: 560px) {
                            font-size: 18px;
                            line-height: 26px;
                        }
                        @media (max-width: 370px) {
                            font-size: 15px;
                            line-height: 23px;
                        }
                }
        }
        &__3d {
            position: absolute;
            top: -200px;
            left: 0;
            right: 0;
                &--figma {
                    width: 280px;
                    height: 280px;
                    position: absolute;
                    left: 10%;
                    top: 100px;
                        @media (min-width: 1735px) {
                            top: 30px;
                        }
                        @media (min-width:1800px) and (max-width: 1940px) {
                            left: 7%;
                        }
                        @media (max-width: 1330px) {
                            left: 7%;
                        }
                        @media (max-width: 1240px) {
                            top: 450px;
                            left: 3%;
                        }
                        @media (max-width: 1100px) {
                            left: 5%;
                            top: 630px;
                        }
                        @media (max-width: 870px) {
                            left: 5%;
                            top: 650px;
                        }
                        
                }
                &--react {
                    width: 350px;
                    height: 350px;
                    position: absolute;
                    right: 12%;
                    top: -40px;
                        @media (min-width: 1815px) {
                            top: -60px;
                        }
                        @media (max-width: 1440px) {
                            top: -80px;
                        }
                }
                &--vscode {
                    width: 150px;
                    height: 150px;
                    position: absolute;
                    right: 5%;
                    top: 380px; 
                        @media (min-width: 1815px) {
                            right: 10%;
                        }
                        @media (max-width: 1100px) {
                            top: 420px;
                        }
                }
                &--illustrator {
                    width: 380px;
                    height: 380px;
                    position: absolute;
                    left: 0;
                    top: 400px;
                        @media (min-width: 2100px) {
                            left: 2%;
                            top: 300px;
                        }
                        @media (min-width: 1815px) {
                            top: 300px;
                        }
                        @media (max-width: 1240px) {
                            top: 0px;
                            left: 3%;
                        }
                        @media (max-width: 1160px) {
                            top: -50px;
                        }
                        @media (max-width: 1100px) {
                            left: -3%;
                            top: 130px;
                        }
                        @media (max-width: 1000px) {
                            top: -50px;
                        }
                }
                @media (max-width: 768px) {
                    display: none;
                }
        }
        @media (max-width: 1100px) {
            margin-bottom: 320px;
        }
        @media (max-width: 768px) {
            margin-top: 100px;
            margin-bottom: 80px;
            padding: 0 5%;
        }
        @media (max-width: 600px) {
            margin-top: 60px;
        }
}

.cover {
    display: none!important;
}