.portfolio {
    padding: 0 8%;
    padding-bottom: 250px;
    background-color: #0D0D0D;
    display: flex;
    flex-direction: column;
    align-items: center;
        &__title {
            font-family: 'AmericaExpRg';
            font-size: 56px;
            text-align: center;
            padding-top: 150px;
            margin-bottom: 15px;
                @media (max-width: 1024px) {
                    padding-top: 80px;
                }
                @media (max-width: 600px) {
                    font-size: 44px;
                    padding-top: 50px;
                }
                @media (max-width: 450px) {
                    padding-top: 30px;
                }
        }
        &__subtitle {
            color: #4e4e4e;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            margin: auto;
            width: 35%;
            min-width: 490px;
            margin-bottom: 150px;
                @media (max-width: 1024px) {
                    margin-bottom: 100px;
                }
                @media (max-width: 600px) {
                    font-size: 18px;
                    line-height: 22px;
                    width: auto;
                    min-width: auto;
                }
                @media (max-width: 450px) {
                    margin-bottom: 70px;
                }
        }
        &__projects {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            column-gap: 100px;
            row-gap: 200px;
                &__part {
                    position: relative;
                        &__extend {
                            background-color: #e9e9e9;
                            border-radius: 3px 3px 0 0;
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            left: 0px;
                            top: 1px;
                            transition: transform .3s ease-in-out;
                                &--less {
                                    content: '';
                                    background-color: black;
                                    width: 20px;
                                    height: 3px;
                                    position: absolute;
                                        @media (min-width: 1600px) {
                                            width: 25px;
                                            height: 4px;
                                        }
                                }
                                &--plus {
                                    content: '';
                                    background-color: black;
                                    width: 20px;
                                    height: 3px;
                                    position: absolute;
                                    transform: rotate(90deg);
                                    transition: transform .3s ease-in-out;
                                        &--active {
                                            transform: rotate(0deg);
                                        }
                                        @media (min-width: 1600px) {
                                            width: 25px;
                                            height: 4px;
                                        }
                                }
                                &:hover {
                                    cursor: pointer;
                                }
                                @media (min-width: 2000px) {
                                    width: 70px;
                                    height: 70px;
                                }
                                @media (min-width: 500px) {
                                    width: 60px;
                                    height: 60px;
                                }
                        }
                        &:hover > .portfolio__projects__part__extend {
                            transform:translateY(-40px);
                                @media (min-width: 2000px) {
                                    transform:translateY(-70px);
                                }
                                @media (min-width: 500px) {
                                    transform:translateY(-60px);
                                }
                        }
                        &__swiper {
                                &__picture {
                                    max-width: 100%;      
                                }
                                &__video {
                                    height: 100%;
                                    width: 660px; /* 100 * 16 / 9 */
                                    min-width: 100%;
                                    min-height: 660px; /* 100 * 9 / 16 */
                                }
                                &:hover {
                                    cursor: grab;
                                }
                                &:active {
                                    cursor: grabbing;
                                }
                        }
                        &__description {
                            position: absolute;
                            width: 100%;
                            height: 99.4%;
                            overflow: hidden;
                                &__background {
                                    content: '';
                                    height: 250%;
                                    width: 150%;
                                    background-color: #e9e9e9;
                                    position: absolute;
                                    bottom: 80%;
                                    right: 80%;
                                    z-index: 10;
                                    transform: rotate(45deg);
                                    transition: all 1.2s 2.8s ease-in-out;
                                        &--active {
                                            right: -50%;
                                            bottom: -50%;
                                            transition: all 1.2s ease-in-out;
                                        }
                                }
                                &__content {
                                    height: 100%;
                                    padding: 0 5%;
                                    position: relative;
                                    display: flex;
                                    flex-direction: column;
                                    z-index: 0;
                                    transition: z-index 0s 3s linear;
                                        &__name {
                                            width: 50%;
                                                &__title {
                                                    color: black; 
                                                    font-family: 'AmericaMonoMd';
                                                    font-size: 40px; 
                                                    margin-bottom: 5px;   
                                                    transform: translateX(-50px);
                                                    opacity: 0;
                                                    transition: all 1.2s 1.8s ease-in-out;
                                                        &--active {
                                                            opacity: 1;
                                                            transform: translateX(0px);
                                                            transition: all 1.2s .8s ease-in-out;;
                                                        }     
                                                        @media (max-width: 1430px) {
                                                            margin-top: 20px;
                                                            margin-bottom: 0;
                                                        }    
                                                        @media (max-width: 1180px) {
                                                            margin-top: 40px;
                                                        }    
                                                        @media (max-width: 720px) {
                                                            margin-top: 20px;
                                                        }
                                                        @media (max-width: 580px) {
                                                            font-size: 30px;
                                                        }
                                                        @media (max-width: 420px) {
                                                            font-size: 25px;
                                                        }
                                                        @media (max-width: 350px) {
                                                            font-size: 22px;
                                                        }
                                                }
                                                &__resume {
                                                    font-size: 24px;
                                                    line-height: 30px;
                                                    color: #4D4D4D;
                                                    margin-top: 15px;
                                                    transform: translateX(-50px);
                                                    opacity: 0;
                                                    transition: all 1.2s 1.3s ease-in-out;
                                                        &--active {
                                                            opacity: 1;
                                                            transform: translateX(0px);
                                                            transition: all 1.2s 1s ease-in-out;;
                                                        } 
                                                        @media (max-width: 1520px) {
                                                            font-size: 22px;
                                                            line-height: 26px;
                                                        }
                                                        @media (max-width: 1400px) {
                                                            font-size: 20px;
                                                            line-height: 24px;
                                                            margin-top: 10px;
                                                        }
                                                        @media (max-width: 1180px) {
                                                            font-size: 28px;
                                                            line-height: 36px;
                                                            margin-top: 20px;
                                                            min-width: 385px;
                                                        }
                                                        @media (max-width: 800px) {
                                                            font-size: 24px;
                                                            line-height: 32px;
                                                            min-width: 320px;
                                                        }
                                                        @media (max-width: 720px) {
                                                            font-size: 20px;
                                                            line-height: 26px;
                                                            margin-top: 10px;
                                                        }
                                                        @media (max-width: 580px) {
                                                            font-size: 16px;
                                                            line-height: 20px;
                                                        }
                                                        @media (max-width: 420px) {
                                                            font-size: 14px;
                                                            line-height: 18px;
                                                            min-width: 260px;
                                                        }
                                                        @media (max-width: 350px) {
                                                            line-height: 16px;
                                                            margin-top: 10px;
                                                        }
                                                }
                                        }
                                        &__stack {
                                            align-self: flex-end;
                                                &__title {
                                                    opacity: 0;
                                                    transform: translateX(50px);
                                                    transition: all 1.2s 1.15s ease-in-out;
                                                        &__text {
                                                            font-family: 'AmericaExpMd';
                                                            color: black;
                                                            font-size: 26px;
                                                            margin: 0px;
                                                                @media (max-width: 1180px) {
                                                                    font-size: 34px;
                                                                    margin-top: 60%;
                                                                }
                                                                @media (max-width: 1050px) {
                                                                    margin-top: 30%;
                                                                }
                                                                @media (max-width: 900px) {
                                                                    font-size: 30px;
                                                                    margin-top: 0;
                                                                }
                                                                @media (max-width: 690px) {
                                                                    font-size: 26px;
                                                                }
                                                                @media (max-width: 580px) {
                                                                    font-size: 22px;
                                                                }
                                                                @media (max-width: 420px) {
                                                                    font-size: 18px;
                                                                }
                                                        }
                                                        &__line {
                                                            content: '';
                                                            height: 2px;
                                                            width: 25%;
                                                            background-color: black;
                                                            position: absolute;
                                                        }
                                                        &--active{
                                                            opacity: 1;
                                                            transform: translateX(0px);
                                                            transition: all 1.2s 1.7s ease-in-out;
                                                        }
                                                }
                                                &__list {
                                                    list-style-type: none;
                                                    padding-left: 0;
                                                    margin-top: 15px;
                                                    opacity: 0;
                                                    transform: translateX(50px);
                                                    transition: all 1.2s .9s ease-in-out;
                                                        &__part {
                                                            display: flex;
                                                            align-items: center;
                                                                &__name {
                                                                    color: #272727;
                                                                    font-size: 24px;
                                                                    font-weight: 500;
                                                                    margin: 12px 0;
                                                                        @media (max-width: 1180px) {
                                                                            font-size: 28px;
                                                                        }
                                                                        @media (max-width: 800px) {
                                                                            font-size: 24px;
                                                                        }
                                                                        @media (max-width: 690px) {
                                                                            font-size: 20px;
                                                                        }
                                                                        @media (max-width: 580px) {
                                                                            font-size: 18px;
                                                                        }
                                                                        @media (max-width: 460px) {
                                                                            margin: 5px 0;
                                                                        }
                                                                        @media (max-width: 420px) {
                                                                            font-size: 14px;
                                                                        }
                                                                }
                                                                &__logo {
                                                                    margin-left: 10px;
                                                                        @media (max-width: 690px) {
                                                                            width: 30px;
                                                                        }   
                                                                        @media (max-width: 460px) {
                                                                            width: 25px;
                                                                        }
                                                                        @media (max-width: 420px) {
                                                                            width: 20px;
                                                                        }
                                                                }
                                                        }
                                                        &--active{
                                                            opacity: 1;
                                                            transform: translateX(0px);
                                                            transition: all 1.2s 1.9s ease-in-out;
                                                        }
                                                }
                                                @media (max-width: 420px) {
                                                    align-self: flex-start;
                                                    margin-top: 20px;
                                                }
                                                @media (max-width: 350px) {
                                                    margin-top: 10px;
                                                }
                                        }
                                        &__nav {
                                            display: flex;
                                            align-items: center;
                                            margin-top: auto;
                                            font-family: 'AmericaExpRg';
                                            margin-bottom: 3%;
                                                &__live {
                                                    text-decoration: none;
                                                    color: #272727;
                                                    font-size: 20px;
                                                    margin-right: 8%;
                                                    opacity: 0;   
                                                    transition: all 1.2s .4s ease-in-out;
                                                        &--active{
                                                            opacity: 1;
                                                            transition: all 1.2s 2.4s ease-in-out;
                                                        }
                                                        &:hover {
                                                            cursor: pointer;
                                                        }
                                                        &:after {
                                                            display: block;
                                                            content: '';
                                                            background-color: black;
                                                            border-radius: 50px;
                                                            width: 90%;
                                                            height: 2px;
                                                            margin: auto;
                                                            transform: scaleX(0);
                                                            transform-origin: left;
                                                            transition: transform .33s ease-in-out
                                                        }
                                                        &:hover:after {
                                                            transform: scaleX(1);
                                                        }
                                                        @media (max-width: 1180px) {
                                                            font-size: 30px;
                                                        }
                                                        @media (max-width: 800px) {
                                                            font-size: 27px;
                                                        }
                                                        @media (max-width: 580px) {
                                                            font-size: 20px;
                                                        }
                                                        @media (max-width: 420px) {
                                                            margin-right: 0;
                                                            font-size: 18px;
                                                            min-width: 100px;
                                                        }
                                                        @media (max-width: 350px) {
                                                            font-size: 16px;
                                                        }
                                                }
                                                &__code {
                                                    text-decoration: none;
                                                    color: #272727;
                                                    font-size: 16px;
                                                    opacity: 0;
                                                    transition: all 1.2s ease-in-out;
                                                        &--active{
                                                            opacity: 1;
                                                            transition: all 1.2s 2.8s ease-in-out;
                                                        }
                                                        &:hover {
                                                            cursor: pointer;
                                                        }
                                                        &:after {
                                                            display: block;
                                                            content: '';
                                                            background-color: black;
                                                            border-radius: 50px;
                                                            width: 90%;
                                                            height: 2px;
                                                            margin: auto;
                                                            transform: scaleX(0);
                                                            transform-origin: left;
                                                            transition: transform .33s ease-in-out
                                                        }
                                                        &:hover:after {
                                                            transform: scaleX(1);
                                                        }
                                                        &--private {
                                                            &::after {
                                                                display: none;
                                                            }
                                                            &:hover {
                                                                cursor: default;
                                                            }
                                                        }
                                                        @media (max-width: 1180px) {
                                                            font-size: 26px;
                                                        }
                                                        @media (max-width: 800px) {
                                                            font-size: 23px;
                                                        }
                                                        @media (max-width: 580px) {
                                                            font-size: 16px;
                                                            min-width: 100px;
                                                        }
                                                        @media (max-width: 420px) {
                                                            font-size: 14px;
                                                            margin-top: 10px;
                                                        }
                                                }
                                                @media (max-width: 420px) {
                                                    flex-direction: column;
                                                    position: absolute;
                                                    right: 20px;
                                                    bottom: 20px;
                                                }
                                                @media (max-width: 350px) {
                                                    bottom: 8px;
                                                }
                                        }
                                        &--active {
                                            z-index: 20;
                                            transition: z-index 0s linear;
                                        }
                                }
                        }
                        @media (max-width: 1520px) {
                            min-width: 520px;
                            min-height: 520px;
                        }
                        @media (max-width: 600px) {
                            min-width: auto;
                            min-height: auto;
                        }
                }
                @media (max-width: 1650px) {
                    row-gap: 150px;
                }
                @media (max-width: 1300px) {
                    row-gap: 100px;
                    column-gap: 70px;
                }
                @media (max-width: 1180px) {
                    grid-template-columns: repeat(1, 1fr);
                    grid-template-rows: repeat(6, 1fr);
                }
                @media (max-width: 800px) {
                    row-gap: 70px;
                }
                @media (max-width: 440px) {
                    row-gap: 60px;
                }
        }
        @media (max-width: 1024px) {
            padding-bottom: 200px;
        }
        @media (max-width: 900px) {
            padding-bottom: 120px;
        }
        @media (max-width: 450px) {
            padding-bottom: 90px;
        }
        @media (max-width: 350px) {
            padding-bottom: 80px;
        }
}


// Handle size problem of Swiper inside Grid 
.portfolio__projects__part {
display: grid;
}
.portfolio__projects__part .portfolio__projects__part__swiper {
    max-width: 100%;
}

// Change the style of the Swiper navigation arrows
:root {
    --swiper-navigation-color: white;
}

.swiper-button-prev {
    display: none;
}
.swiper-button-prev::after {
    display: none;
}
.swiper-button-next {
    display: none;
}
.swiper-button-next::after {
    display: none;
}