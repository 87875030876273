.contact {
    padding: 250px 8% 0 8%;
    background-color: transparent;
    display: flex;
    background-color: #0d0d0d;
    justify-content: left;
    padding-bottom: 200px;
    position: relative;
    transition: background-color 1.5s ease-in-out;
        &__title {
            color: white;
            font-family: 'AmericaExpRg';
            font-size: 120px;
            margin: 0;
            position: absolute;
            top: 560px;
            left: -10%;
            transform: rotate(270deg);
            transition: all 1s ease-in-out;
                &--active {
                    color: black;
                }
                @media (max-width: 1450px) {
                    position: relative;
                    top: -100px;
                    left: 0;
                    text-align: center;
                    transform: rotate(0);
                }
                @media (max-width: 900px) {
                    font-size: 90px;
                }
                @media (max-width: 620px) {
                    font-size: 70px;
                }
                @media (max-width: 480px) {
                    font-size: 50px;
                }
                @media (max-width: 380px) {
                    font-size: 44px;
                }
        }
        &__form {
            width: 70%;
            margin-left: 20%;
            color: white;
            transition: all 1s ease-in-out;
                &__info {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 80px;
                        &__field {
                            width: 45%;
                            display: flex;
                            flex-direction: column;
                                &__label {
                                    font-family: 'AmericaExpMd';
                                    font-size: 22px;
                                    margin-bottom: 15px;
                                        @media (max-width: 480px) {
                                            font-size: 19px;
                                        }
                                }
                                &__input {
                                    border: 3px solid white;
                                    border-top: none;
                                    outline: none;
                                    background-color: transparent;
                                    font-family: 'AmericaExpRg';
                                    font-size: 20px;
                                    transition: all 1s ease-in-out;
                                        &--active {
                                            border: 3px solid black;
                                            border-top: none;
                                        }
                                        @media (max-width: 900px) {
                                            margin-bottom: 50px;
                                        }
                                }
                                @media (max-width: 900px) {
                                    width: auto;
                                }
                        }
                        @media (max-width: 900px) {
                            flex-direction: column;
                            margin-bottom: 0;
                        }
                }
                &__message {
                    display: flex;
                    flex-direction: column;
                    margin-top: 120px;
                        &__label {
                            font-family: 'AmericaExpMd';
                            font-size: 22px;
                            margin-bottom: 15px;
                                @media (max-width: 480px) {
                                    font-size: 19px;
                                }
                        }
                        &__input {
                            border: 3px solid white;
                            outline: none;
                            padding: 3% 3% 100px 3%;
                            max-height: 150px;
                            margin: 0;
                            background-color: transparent;
                            font-family: 'AmericaExpRg';
                            transition: all 1s ease-in-out;
                                &--active {
                                    border: 3px solid black;
                                }
                        }
                        @media (max-width: 900px) {
                            margin-top: 60px;
                        }
                        @media (max-width: 600px) {
                            margin-top: 35px;
                        }
                }
                &__error {
                    margin-top: 10px;
                    color: red;
                }
                &__submit {
                    width: 20%;
                    min-width: 200px;
                    margin-top: 45px;
                    background-color: white;
                    border: 3px solid white;
                    padding: 25px 60px 25px 60px;
                    position: relative;
                    overflow: hidden;
                    transition: all 1s ease-in-out;
                        &__text {
                            color: black;
                            font-family: 'AmericaExpMd';
                            font-size: 22px;
                            margin: 0;
                            position: relative;
                            z-index: 1;
                            transition: all 1s;
                                &--active {
                                    color: white;
                                }
                        }
                        &::before {
                            content: '';
                            position: absolute;
                            right: 100%;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: white;
                            transition: all 1s;
                            border-radius: 30px;
                        }
                        &:hover::before {
                            right: 0;
                            border-radius: 0;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                        &:hover > .contact__form__submit__text {
                            color: black;
                        }
                        &--active {
                            background-color: black;
                            border: 3px solid black;
                        }
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                }
                &--active {
                    color: black;
                }
                @media (max-width: 1450px) {
                    margin-left: 0;
                    min-width: 750px;
                }
                @media (max-width: 900px) {
                    min-width: 90%;
                }
                @media (max-width: 480px) {
                    margin-top: -50px;
                }
        }
        @media (max-width: 1450px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        @media (max-width: 900px) {
            padding-top: 180px;
            padding-bottom: 100px;
        }
        @media (max-width: 480px) {
            padding-top: 160px;
            padding-bottom: 80px;
        }
}


.contactActive {
    background-color: #efefef;
    transition: all 1.5s ease-in-out;
}