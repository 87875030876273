.header {
    padding: 0 10%;
    font-family: 'AmericaMonoMd';
    font-size: 16px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    z-index: 5;
        &__logo {
            display: flex;
            align-items: center;
                &__asset {
                    width: 60px;
                }
                &__name {
                    font-family: 'AmericaExpRg';
                    font-size: 18px;
                    margin: 0;
                    margin-left: 10px;
                    margin-top: 5px;
                }
        }
        &__nav {
            width: 27%;
            transition: all .3s ease-in-out;
                &__list {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    list-style-type: none;
                    min-width: 360px;
                    max-width: 400px;
                    padding-left: 0;
                        &__link {
                            font-size: 18px;
                            transition: .5s all ease-in-out; 
                                &:hover {
                                    cursor: pointer;
                                    opacity: .85;
                                }
                                &:after {
                                    display: block;
                                    content: '';
                                    background-color: white;
                                    border-radius: 50px;
                                    width: 70%;
                                    height: 2px;
                                    margin: auto;
                                    transform: scaleX(0);
                                    transition: transform .25s ease-in-out
                                }
                                &:hover:after {
                                    transform: scaleX(1);
                                }
                                &--last {
                                    font-weight: 500;
                                    border: solid 2px white;
                                    border-radius: 25px;
                                    padding: 6px 18px 4px 18px;
                                    transition: .5s all ease-in-out;
                                    margin-left: 12%;
                                    margin-bottom: 2px;
                                        &:hover {
                                            cursor: pointer;
                                            background-color: white;
                                            color: black;
                                        }
                                        @media (max-width: 1040px) {
                                            margin-left: 8%;
                                        }
                                        @media (max-width: 839px) {
                                            margin: 20px 0;
                                            font-size: 26px;
                                            padding: 9px 22px 8px 22px;
                                        }

                                }
                                @media (max-width: 839px) {
                                    margin: 20px 0;
                                    font-size: 26px;
                                }
                        } 
                        @media (max-width: 1240px) {
                            margin-left: -20%;
                        }
                        @media (max-width: 1160px) {
                            margin-left: -40%;
                            min-width: 330px;
                        }
                        @media (max-width: 1040px) {
                            margin-left: -50%;
                            min-width: 320px;
                        }
                        @media (max-width: 839px) {
                            position: fixed;
                            left: 50%;
                            transform: translateX(-50%);
                            flex-direction: column;
                            align-items: center;
                        }
                }
                &--active {
                    z-index: 200;
                    opacity: 1!important;
                    transition: all .3s .5s ease-in-out;
                }
                @media (max-width: 839px) {
                    opacity: 0;
                    width: auto;
                    position: fixed;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 20%;
                }
        }
        &__side {
            z-index: 200;
                &__icon {
                    width: 30px;
                    height: 4px;
                    background-color: white;
                    margin: 6px 0;
                    border-radius: 50px;
                    transition: 0.4s all ease-in-out;  
                        &--01 {
                            transform: translate(0, -10px) rotate(45deg);
                        }
                        &--02 {
                            opacity: 0;
                        }
                        &--03 {
                            transform: translate(0, 10px) rotate(-45deg);
                        }
                }
                &:hover {
                    cursor: pointer;
                }
                &--active {
                    position: fixed;
                    right: 10%;
                    @media (max-width: 650px) {
                        right: 3%;
                    }
                }
                @media (min-width: 840px) {
                    display: none;
                }
        }
        &__background {
            opacity: 0;
            z-index: -1;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ffffff1a;
            backdrop-filter: blur(40px);
            transition: all .5s .2s ease-in-out;
                &--active {
                    z-index: 100;
                    opacity: 1;
                    transition: all .5s ease-in-out;
                }
        }
        @media (max-width: 650px) {
            padding: 0 3%;
        }
}